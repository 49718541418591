<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="120px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="打印机名称" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="打印机名称"
        />
      </el-form-item>
      <el-form-item label="打印机编号" prop="sn">
        <el-input
          v-model="dataForm.sn"
          :disabled="disabled"
          placeholder="打印机编号"
        />
      </el-form-item>
      <el-form-item label="所属门店" prop="shopsId">
        <el-select
          v-model="dataForm.shopsId"
          :disabled="disabled"
          clearable
          filterable
          placeholder="所属门店"
          class="width185"
        >
          <el-option
            v-for="shops in shopsList"
            :key="shops.id"
            :label="shops.name"
            :value="shops.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="存根打印机" prop="stubSn">
        <el-input
          v-model="dataForm.stubSn"
          :disabled="disabled"
          placeholder="存根打印机"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { keys, pick } from 'lodash';

export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        name: '',
        sn: '',
        shopsId: '',
        stubSn: '',
      },
      shopsList: [],
      dataRule: {
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.$http({
      url: '/mall/shops/queryAll',
      method: 'get',
    }).then(({ data }) => {
      if (data && data.code === 0) {
        this.shopsList = data.list;
      }
    });
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/sys/printer/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.printer, keys(this.dataForm));
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/sys/printer/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
